import { lazy, Suspense } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import Loading from "./components/Loading";

const ConnectProgram = lazy(() => import("./containers/"));
const DealerConnect = lazy(() =>
  import("./containers/DealerConnect/DealerConnect")
);
const DncConnect = lazy(() => import("./containers/DncConect/DncConnect"));
const RuleContent = lazy(() => import("./containers/RuleContent/RuleContent"));
const ModalConnect = lazy(() => import("./containers/ModalConnect"));

function App(props) {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={"/bay"} />} />
          <Route path="/bay" component={ConnectProgram} />
          <Route path="/dealer-connect" component={DealerConnect} />
          <Route path="/dnc-connect" component={DncConnect} />
          <Route path="/rule-content" component={RuleContent} />
          <Route path="/dealer-modal-connect">
            <ModalConnect type="dealer" />
          </Route>
          <Route path="/dnc-modal-connect">
            <ModalConnect type="dnc" />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
